import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import StageComponent from './StageComponent';
import ManageStageComponent from './ManageStageComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import '../../styles/typography.scss';
import '../../styles/navigation.scss';
import '../../styles/stage.scss';

const networking = require('../../Networking/API');

function FeedbackComponent(app: any) {
    const location = useLocation() as any;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [stages, setStages] = useState(null) as any[];
    const [addNew, setAddNew] = useState(false)

    var versions: any[] = [];
    let list = null;

    if (!stages) {
        getData();
    }

    function getData() {
        networking.getStages(app.app.id).then((stages: any) => {
            setStages(stages);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function addNewStage(open: boolean) {
        setAddNew(open)
    }

    const didAddNewStage = (event: any) => {
        getData();
    };

    function removeStage(stage: any) {
        setLoading(true);

        networking.deleteStage(stage.id).then((stage: any) => {
            setLoading(false);
            getData();
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
        });
    }

    const updateStatus = (stage: any, e: any) => {
        setLoading(true);
        let status = e.target.checked ? 'active' : 'inactive';

        networking.updateStage(stage.id, status).then((stages: any) => {
            setLoading(false);
            getData();
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function share() {
        var shareMessage = app.app.id;

        navigator.clipboard.writeText(shareMessage);
    }

    if (stages) {
        list = stages.map((stage: any, index: number) =>
            <div className='accordion' id='reviewAccordion'>
                <div className='accordion-item mb-2'>
                    <div className='row'>
                        <div className='col-auto my-auto ps-5'>
                            <div className='form-check form-switch'>
                                {
                                    stage.status === 'active' &&
                                    <input onChange={(e) => updateStatus(stage, e)} role="switch" type="checkbox" className='form-check-input' id={'flexSwitchCheckDefault_' + index} checked />
                                }
                                {
                                    stage.status === 'inactive' &&
                                    <input onChange={(e) => updateStatus(stage, e)} role="switch" type="checkbox" className='form-check-input' id={'flexSwitchCheckDefault_' + index} />
                                }
                            </div>
                        </div>
                        <div className='col ps-0'>
                            <p className='iron bold accordion-header' id={'flush-heading ' + index} >
                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={'#flush-collapse' + index}>
                                    {stage.name}
                                </button>
                            </p>
                        </div>
                        <div className='col-auto pe-4 my-auto'>
                            <button className='btn btn-danger' onClick={() => removeStage(stage)}> <FontAwesomeIcon icon={faTrashCan} color='#fff' /> </button>
                        </div>
                    </div>
                    <div className='accordion-collapse collapse' id={'flush-collapse' + index}>
                        <StageComponent app={app.app.id} stage={stage} />
                    </div>
                </div>
            </div>

        )
    }

    return (
        <div className='row gy-5'>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col-lg'>
                    <div className='card ps-5 pe-5'>
                        <div className='row mt-4 mb-5'>
                            <div className='col-auto my-auto'>
                                <span className='iron bold hint'>App Id</span>
                            </div>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='bg-doriean p-3 id-sample'><pre className='light-iron mb-0'>{app.app.id}</pre></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-auto my-auto'>
                                <button type="button" onClick={share} className='btn light-iron bold legend'>
                                    <span className='me-1'><FontAwesomeIcon icon={faCopy} color='#8C8CA1' /></span>
                                </button>
                            </div>
                            <p className='ps-5 mt-2 ms-4 light-iron semi-bold hint'>Copy this code and use it to configure the SDK. For more information on how to install and configure the appinion SDK please refer to our documentation.
                                <span className='ms-1'><Link to='/docs'>Open documentation</Link></span></p>
                        </div>
                    </div>
                    <div className='card mt-3'>
                        <div className='apps mb-4'>
                            <button onClick={() => addNewStage(true)} className='btn'><FontAwesomeIcon icon={faAdd} /><span className='ms-2'>Create new stage</span></button>
                        </div>
                        {
                            addNew &&
                            <div className='row ps-5 pe-5 mb-5'>
                                <button onClick={() => addNewStage(false)} className='ms-3 me-3 mb-4 btn btn-outline hint bold'> Close </button>
                                <ManageStageComponent app={app.app} handler={didAddNewStage} />
                            </div>
                        }
                        {stages.length === 0 &&
                            <div className='card'>
                                <EmptyMessageComponent
                                    image={''}
                                    title={'Oh no!! You don\'t have stages created yet!.'}
                                    description={'A stage is whre you can ask feedback to your user. It can be at the end of an important flow, or anywhere on your application.'} />
                            </div>
                        }
                        {list}
                    </div>

                </div>
            }
        </div>
    );
}

export default FeedbackComponent;