import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import PublicNavigationHeader from '../../components/PublicNavigationHeader';
import ClientManagementComponent from '../Client Management/ClientManagementComponent';

import bg from '../../resources/bg.png'
import '../../styles/authentication.scss';
import PricingComponent from '../PricingComponent';

const networking = require('../../Networking/API');

function ClientManagementPage() {
    let navigate = useNavigate();

    const [parameters, setParameters] = useSearchParams();
    const [targetPlan, setTargetPlan] = useState('');

    const plan = parameters.get('plan')
    const mode = parameters.get('mode')

    if (plan !== null) {
        setTargetPlan(plan);
        parameters.delete('plan')
        setParameters(parameters)
    }

    const subscribed = (event: any) => {
        navigate({ pathname: '/authenticate' })
    };

    return (
        <div className=''>
            <div className='container mb-5 pb-5'>
                <PublicNavigationHeader menu={false} />
            </div>
            <div className='container'>
                <div className='row gy-3 mt-3'>
                    <div className='col p-3'>
                        <div style={{ width: 100 + '%' }}>
                            <ClientManagementComponent targetPlan={targetPlan} mode={mode} handler={subscribed} />
                        </div>
                    </div>
                    <div className='col'>
                        {targetPlan === 'Basic' &&
                            <PricingComponent
                                plan={targetPlan}
                                mode={mode}
                                description={'An excellent entry point for those who want to get a feel for our services without any cost. This plan provides access to essential features, making it ideal for individual users or small-scale projects.'}
                                features={
                                    [
                                        "1 App",
                                        "Reviews",
                                        "Heart beat",
                                        "2 topics on Keywords",
                                        "Last month report"
                                    ]
                                }
                                subscription={true} />
                        }
                        {targetPlan === 'Indie' &&
                            < PricingComponent
                                plan={targetPlan}
                                mode={mode}
                                description={'For independent developers and small teams, our Indie Plan is the perfect choice. Affordable and feature-rich, it\'s the next step for those looking to elevate their projects.'}
                                features={
                                    [
                                        "up to 3 Apps",
                                        "Reviews",
                                        "Heart beat",
                                        "5 topics on Keywords",
                                        "3 months report",
                                        "Radar"
                                    ]
                                }
                                price={mode == 'monthly' ? "€19,90" : "€199"}
                                subscription={true} />
                        }
                        {targetPlan === 'Business' &&
                            < PricingComponent
                                plan={targetPlan}
                                mode={mode}
                                description={'Businesses with multiple apps and complex requirements will find our Business Plan tailored to their needs. With premium features for collaboration and analytics, this plan ensures your business can thrive in the digital landscape.'}
                                features={
                                    [
                                        "up to 10 Apps",
                                        "Reviews",
                                        "Heart beat",
                                        "Unlimited topics on Keywords",
                                        "6 months report",
                                        "Radar",
                                        "Pathfinder",
                                        "Snapshot"
                                    ]
                                }
                                price={mode == 'monthly' ? "€49,90" : "€499"}
                                subscription={true} />
                        }
                        {!targetPlan &&
                            <div className='col-sm'>
                                <div className='section mt-3 p-5' style={{ height: 100 + "%" }}>
                                    <div className='pe-5'>
                                        <h5 className='heading pe-5 mb-4 white'>Ready to elevate the way you handle your App reviews and ratings?</h5>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ClientManagementPage;