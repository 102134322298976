import React from 'react';

import AlertComponent from './AlertComponent';
import DashboardComponent from './Dashboard/DashboardComponent';
import ReviewsComponent from './Reviews/ReviewsComponent';
import HeartBeatComponent from './Heartbeat/HeartBeatComponent';
import KeywordsComponent from './Keywords/KeywordsComponent';
import FeedbackComponent from './Feedback/FeedbackComponent';
import ClientManagementComponent from './Client Management/ClientManagementComponent';
import ReportsComponent from './Reports/ReportsComponents';
import PredictorComponent from './Predictor AI/PredictorComponent';
import RoadmapGenerator from './Predictor AI/RoadmapGenerator';
import PersonaGenerator from './Predictor AI/PersonaGenerator';

function AppDataContainer({ component, app, subscription, handler }: any) {
    if (component === 0) {
        return <DashboardComponent app={app} />
    } else if (component === 1) {
        return <ReviewsComponent app={app} />
    } else if (component === 2) {
        return <HeartBeatComponent app={app} />
    } else if (component === 3) {
        return <KeywordsComponent subscription={subscription} app={app} />
    } else if (component === 4) {
        return <PredictorComponent app={app} />
    } else if (component === 5) {
        return <RoadmapGenerator app={app} />
    } else if (component === 6) {
        return <PersonaGenerator app={app} />
    } else if (component === 7) {
        return <ReportsComponent app={app} subscription={subscription} handler={handler} />
    } else if (component === 8) {
        return <FeedbackComponent app={app} />
    } else if (component === 9) {
        return <ClientManagementComponent subscription={subscription} />
    } else if (component === 10) {
        return null;
    } else {
        return <AlertComponent alert={'Error loading component.'} />
    }
}

export default AppDataContainer;