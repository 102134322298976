import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import FeedbackResultComponent from './FeedbackResultComponent';

import '../../styles/typography.scss';
import '../../styles/pills.scss';

import { faL } from '@fortawesome/free-solid-svg-icons';

const networking = require('../../Networking/API');

function StageComponent({ app, stage }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [results, setResults] = useState(null) as any[];

    if(!results) {
        getData();
    }

    function getData() {
        networking.getQueriesForStage(stage.id).then((results: any) => {
            setResults(results);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    var yes: any[] = [];
    var no: any[] = [];
    var cleanResults: any[] = [];
    var completed: any[] = [];
    var notCompleted: any[] = [];
    let list = null;
    let plist = null;
    let versions: any[] = [];

    if (results) {
        yes = results.filter((r: any) => r.vote === true)
        no = results.filter((r: any) => r.vote === false)
        completed = results.filter((r: any) => r.completed === true)
        notCompleted = results.filter((r: any) => r.completed === false)

        versions = [... new Set(yes.map((r:any) => r.version))];

        let answerResults = no.filter((r:any) => r.feedback != '').filter((r:any) => r.feedback != null).filter((r:any) => r.answers.length == 0);
        let questionsResults = no.filter((r:any) => r.answers.length > 0);

        cleanResults = answerResults.concat(questionsResults);

        list = cleanResults.map((feedback: any, index: number) =>
            <div className=''>
                <FeedbackResultComponent feedback={ feedback }/>
            </div>
        )

        plist = versions.map((v: any, index: number) =>
            <div className=''>
                <p className='iron bold legend'>Version: <span className='light-iron legend'>{ v }</span></p>
            </div>
        )
    }

    return(
        <div className='row p-5'>
            { error && <div> <AlertComponent alert={ error } /> </div>}
            { loading ?
                <LoadingSpinnerComponent/>
                :
            <div className='col-lg'>
                <div className='row'>
                    <p className='iron bold'>Stage information</p>
                    <p className='iron'><span className='light-iron'>I :</span> { stage.first_message }</p>
                    <p className='iron'><span className='light-iron'>II :</span> { stage.second_message }</p>
                    {
                        stage.questions.map((question: string, index: number) =>
                            <li className='iron legend'>
                                { question }
                            </li>
                        )
                    }
                    <p className='iron mt-3'><span className='light-iron'>III :</span> { stage.final_message }</p>

                    <div className='row mt-4 mb-4'>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-auto my-auto'> 
                                    <p className='iron indicator'>{ completed.length }</p>
                                </div>
                            <div className='col my-auto'> 
                                <p className='light-iron'>Completed the process</p>
                            </div>
                            
                        </div>
                        <div className="bg-greenie mb-4 p-1 progress" style={{width:((completed.length / results.length) * 100) +'%'}}></div>
                            <div className='row'>
                                <div className='col-auto my-auto'> 
                                    <p className='iron indicator'>{ notCompleted.length }</p>
                                </div>
                                <div className='col my-auto'> 
                                    <p className='light-iron'>Canceled the process</p>
                                </div>
                            </div>
                            <div className="bg-bloodie mb-3 p-1 progress" style={{width:((notCompleted.length / results.length) * 100) +'%'}}></div>
                    </div>
                <h1 className='light-iron bold mt-5'>Results</h1>
                <div className='border-bottom mb-5'></div>
                    {/* <div className='apps col-auto my-auto'>
                        <button className='btn btn-cta-outline'>Share</button>
                    </div> */}
                </div>
                    <div className='col mb-4'>
                        <p className='iron indicator'>{ yes.length } <span className='semi-bold light-iron legend'>positive 🎉</span></p>
                    </div>
                </div>
                <div>
                    { plist }
                </div>

                <div className='row mt-5'>
                    <div className='col'>
                        <p className='iron indicator'>{ no.length } <span className='semi-bold light-iron legend'>negative</span></p>
                    </div>
                </div>
                                
                <div>
                    { results.count !== 0 && <p className='iron bold'>Negative Feedback</p> }
                    <div className='accordion' id='reviewAccordion'>
                        <div className='accordion-item mb-2'>
                            <p className='iron bold accordion-header' id='flush-heading ' >
                                <button className='accordion-button collapsed iron regular legend' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse'>
                                    Reviews <span className='ms-3 bold'>{ cleanResults.length }</span>
                                </button>
                            </p>
                            <div className='accordion-collapse collapse ps-5 pe-5' id='flush-collapse'>
                                <div className='pt-4 pb-3'>{ list } </div>
                            </div> 
                        </div>
                    </div>
                    <div className='mt-5'>
                    {
                        stage.questions.map((question: string, index: number) =>
                        <div className='row'>
                            <div className='col-auto pill'><p>{ question }</p></div>
                            <div className='col-auto light-iron bold legend'>{ cleanResults.filter((r: any) => r.answers.some((k: string) => question.includes(k))).length } hits</div>
                        </div>
                        )
                    }
                    </div>
                </div>
            </div>
            }
        </div>
        );
}

export default StageComponent;