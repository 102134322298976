import React, { useState } from 'react';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import StarRatingComponent from '../StarRatingComponent';

import '../../styles/appinfooverview.scss';

const networking = require('../../Networking/API');

function AppRatingComponent({ app, type }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [rating, setRating] = useState(null) as any;

    if (!rating) {
        getData();
    }

    function getData() {
        networking.ratingsFor(app.id, type).then((rating: any) => {
            setRating(rating);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <div className='info-overview card pt-3 pb-3 mb-3'>
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    <div className='row'>
                        <div className='col-md-auto my-auto iron legend bold'>
                            {type === 'apple' && 'Appstore'}
                            {type === 'google' && 'Playstore'}
                        </div>
                        <div className='col-md-auto my-auto'>
                            {rating && rating.userRatingCount ?
                                <div>{rating.userRatingCount} <span className='hint light-iron'>ratings</span></div>
                                :
                                <div className='hint bold bloodie'>No ratings available</div>
                            }
                        </div>
                        <div className='col-md-auto my-auto'>
                            {rating &&
                                <div><StarRatingComponent rating={rating.averageUserRating} size={'small'} /></div>
                            }
                        </div>
                        <div className='col-md my-auto'>
                            {rating &&
                                <div className='bold iron hint'>{rating.averageUserRating}</div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AppRatingComponent;