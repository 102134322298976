import React, { useState } from 'react';
import { useLocation } from 'react-router';
import Cookies from 'universal-cookie';

import PrivateNavigationHeader from '../PrivateNavigationHeader';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import InfoBanner from '../InfoBanner';
import AppCard from '../AppCard';
import AlertComponent from '../AlertComponent';
import ClientManagementComponent from '../Client Management/ClientManagementComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import '../../styles/navigation.scss';

import revault_logo from '../../resources/footer_logo.png'
import EmptyMessageComponent from '../EmptyMessageComponent';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');
const cookies = new Cookies();

function AppsListComponent() {
    const location = useLocation() as any;

    const [loading, setLoading] = useState(true);
    const [activeComponent, setActiveComponent] = useState(0);
    const [error, setError] = useState('');
    const [apps, setApps] = useState(null) as any;

    if (!apps) {
        getApps();
    }

    function getApps() {
        networking.appsFor(location.state.id).then((data: any) => {
            setApps(data);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    const openSettings = (event: any) => {
        setActiveComponent(1);
    }

    const openList = (event: any) => {
        setActiveComponent(0);
    }

    if (apps) {
        cookies.remove("toplikes");
        cookies.remove("topdislikes");
        cookies.remove("roadmap");
        cookies.remove("persona");
        var apps_list = apps.map((app: any, index: BigInteger) =>
            <div className='col-md-4'>
                <AppCard app={app} subscription={location.state} />
            </div>
        );
    }

    return (
        <div>
            <PrivateNavigationHeader subscription={location.state} handler={openSettings} />
            {apps && helper.canAddMoreApps(location.state.plan, apps.length) ?
                <InfoBanner title={'Apps'} description={'👍 List of all the apps that you are currently tracking.'} action={'/manage/app'} subscription={location.state} />
                :
                <InfoBanner title={'Apps'} description={'👍 List of all the apps that you are currently tracking.'} />
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='container'>
                    {error &&
                        <div>
                            <AlertComponent alert={error} />
                        </div>
                    }
                </div>
            }
            <div className='container apps-list pb-5 pt-5'>
                {activeComponent === 0 &&
                    <div>
                        {apps && apps.length == 0 &&
                            <EmptyMessageComponent
                                image={revault_logo}
                                title={'You haven\'t linked any app yet.'}
                                description={'Click on the Add new app button above to link your App and start \
                                    analysing your reviews.'} />
                        }
                        <div className='row gy-4'>
                            {apps_list}
                        </div>
                    </div>
                }
                {activeComponent === 1 &&
                    <div>
                        <a onClick={openList}><p className='semi-bold mb-5'><span className='me-3'><FontAwesomeIcon icon={faChevronLeft} /></span>back to list</p></a>
                        <div className='row gy-4'>
                            <ClientManagementComponent subscription={location.state} />
                        </div>
                    </div>
                }
            </div>
            <footer className='p-3 text-center'>
                <a className='no-decoration' href='https://www.appinion.pt' target={'blank'}>
                    <p className='legend light-iron'>© 2023 Appinion All Rights Reserved.</p>
                    <img className='ms-3' height={16} src={revault_logo} alt="logo"></img>
                </a>
            </footer>
        </div>

    );
}

export default AppsListComponent;