import React, { useState } from 'react';
import Cookies from 'universal-cookie';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import AlertComponent from '../AlertComponent';

import '../../styles/predictor.scss';

const networking = require('../../Networking/API');
const cookies = new Cookies();

function PredictTopComponent({ app, type }: any) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState(cookies.get(type)) as any[];

    function getData() {
        setLoading(true);

        networking.getTopData(app.id, type).then((data: any) => {
            createTop(data.content);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function createTop(data: string) {
        let object = JSON.parse(data);
        cookies.set(type, object);

        setData(object);
    }

    return (
        <div className=''>
            {error && <div> <AlertComponent alert={error} /> </div>}

            <div className='row'>
                <div className='col'>
                    <h3 className='iron bold'>
                        {type === 'toplikes' ? 'Most loved' : 'User Pain Points'}
                    </h3>
                    <p className='light-iron legend'>
                        {type === 'toplikes' ? 'What users can\'t get enough of.' : 'The most mentioned problems by our users.'}
                    </p>
                </div>
                <div className='col-auto'>
                    {!data &&
                        <div className='row'>
                            <button type="button" onClick={getData} className='btn btn-cta-primary bg-apporange white bold legend'>
                                Get
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className=''>
                {loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className=''>
                        {data &&
                            <div className='row mt-4 ms-3'>
                                {data.map((i: any) => (
                                    <ul>
                                        <li className='iron info m-0'>
                                            {i.description}
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default PredictTopComponent;