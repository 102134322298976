import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Cookies from 'universal-cookie';
import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

import placeholder from '../../resources/placeholder_icon.svg'
import PricingComponent from '../PricingComponent';

const networking = require('../../Networking/API');
const cookies = new Cookies();

function ClientManagementComponent({ targetPlan, mode, subscription, handler }: any) {
    const location = useLocation();
    const [validation, setValidation] = useState({
        name: "",
        email: "",
        checked: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState('');
    const [icon, setIcon] = useState(subscription ? subscription.profileimage : null);
    const [iconUrl, setIconUrl] = useState("");
    const [name, setName] = useState(subscription ? subscription.name : null);
    const [businessName, setBusinessName] = useState(subscription ? subscription.businessname : null);
    const [email, setEmail] = useState(subscription ? subscription.email : null);
    const [plan, setPlan] = useState('Basic');
    const [planCode, setPlanCode] = useState(subscription ? subscription.plan : 'A');
    const [iconName, setIconName] = useState(null);
    const [checked, setChecked] = useState(subscription ? true : false);

    let plans = [{ "name": "Basic", "code": "A" },
    { "name": "Indie", "code": "AA" },
    { "name": "Business", "code": "AAA" }];

    useEffect(() => {
        checkValidation();
        setSubmitting(false);
    }, [submitting]);

    const checkValidation = () => {
        console.log(name);
        console.log(businessName);
        console.log(email);
        console.log(planCode);
        console.log(checked);
        console.log(icon);

        let errors = validation;
        if (name === null || name.length === 0) {
            errors.name = "Name is required";
        } else {
            errors.name = "";
        }

        if (email === null || email.length === 0) {
            errors.email = "Email is required";
        } else {
            errors.email = "";
        }

        if (checked === false) {
            errors.checked = "To continue, check the box indicating that you have read and accepted the terms.";
        } else {
            errors.checked = "";
        }

        setValidation(errors);
    }

    const loadSubscriptionImage = (event: any) => {
        event.preventDefault();
        setError('');

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
        } else {
            setIcon(file);
            setIconUrl(URL.createObjectURL(file));
            setIconName(file.name);
        }

    }

    const updateName = (event: any) => {
        setValidation({
            name: "",
            email: validation.email,
            checked: validation.checked,
        });
        var m = event.target.value;

        setName(m);
    }

    const updateBusinessname = (event: any) => {
        var m = event.target.value;

        setBusinessName(m);
    }

    const updateEmail = (event: any) => {
        setValidation({
            name: validation.name,
            email: '',
            checked: validation.checked,
        });
        var m = event.target.value;

        setEmail(m);
    }

    const updatePlan = (event: any) => {
        event.preventDefault();
        setError('');

        var m = event.target.value;
        var filtered = plans.filter((c: any) => c.name === m);
        var plan = filtered[0];

        setPlan(plan.name);
        setPlanCode(plan.code);
    }

    const saveNewSubscription = (event: any) => {
        event.preventDefault();

        setSubmitting(true)

        if (name.length === 0 || email.length === 0 || checked !== true) {
            setError("Please, fill all required fields.")
            return
        }

        if (targetPlan) {
            var filtered = plans.filter((c: any) => c.name === targetPlan);
            var planObj = filtered[0];

            setPlanCode(planObj.code);
        } else {
            var filtered = plans.filter((c: any) => c.name === plan);
            var planObj = filtered[0];
            setPlanCode(planObj.code);
        }

        if (subscription) {
            setLoading(true);

            let sub = {
                "id": subscription.id,
                "profileimage": icon,
                "name": name,
                "businessName": businessName,
                "plan": planCode,
                "email": email
            }

            networking.updateSubscription(sub).then((sub: any) => {
                setLoading(false);
                setSuccess('🎉 Great! Your subscription was updated with success!')
            }).catch((error: Error) => {
                setError(error.message);
                setLoading(false);
            });
        } else {
            let sub = {
                "profileimage": icon,
                "name": name,
                "businessName": businessName,
                "plan": planObj.code,
                "email": email
            }

            setLoading(true);

            networking.saveNewSubscription(sub).then((sub: any) => {
                cookies.set('subscription_id', sub.id);

                if (planObj.code !== 'A') {
                    const price = mode == 'monthly' ? 0 : 1

                    networking.checkoutSubscription(sub.plan, price).then((sub: any) => {
                        setLoading(false);

                        if (sub.checkout_url) {
                            window.location.replace(sub.checkout_url);
                        }
                    }).catch((error: Error) => {
                        setError(error.message);
                        setLoading(false);
                    });
                } else {
                    cookies.set('subs_token', sub.id)
                    handler(true);
                }

            }).catch((error: Error) => {
                setError(error.message);
                setLoading(false);
            });
        }
    }

    const openCustomerPortal = (event: any) => {
        setLoading(true);

        networking.customerPortal(subscription, location.pathname).then((sub: any) => {
            setLoading(false);

            if (sub.portal_url) {
                window.open(sub.portal_url, '_blank', 'noopener,noreferer');
            }
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    const handleCheckChange = () => {
        setChecked(!checked);
        setValidation({
            name: validation.name,
            email: validation.email,
            checked: !checked ? '' : validation.checked,
        });
    };

    const close = (event: any) => {
        handler(true);
    }

    function clearForm() {
        setName(null);
        setBusinessName(null);
        setEmail(null);
        setIcon(null);
        setIconName(null);
        setPlan('Basic');
    }

    return (
        <div>
            {error && <div className='p-2'> <AlertComponent alert={error} /> </div>}
            {success && <div className='p-2'> <AlertComponent alert={success} type={1} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    <div className='row'>
                        <div className='p-5 card'>
                            {subscription === undefined &&
                                <div>
                                    {/* <a onClick={close}><p className='semi-bold mb-5'><span className='me-3'><FontAwesomeIcon icon={faChevronLeft} /></span>back</p></a> */}
                                    <div className="mt-3">
                                        <h5 className="iron text-center semi-bold">🎉 You are one step closer to better understand what your users think about your Apps!</h5>
                                    </div>
                                </div>
                            }
                            {subscription !== undefined &&
                                <div>
                                    {/* <a onClick={close}><p className='semi-bold mb-5'><span className='me-3'><FontAwesomeIcon icon={faChevronLeft} /></span>back</p></a> */}
                                    <div className="mt-3">
                                        <h5 className="iron semi-bold">You can manage all your user data here.</h5>
                                    </div>
                                </div>
                            }
                            <div className=''>
                                <p className='light-iron p-0'></p>
                                <div className='pe-4 mb-5'>
                                </div>
                                {/* Only display profile picture setup when editing the profile */}
                                {subscription &&
                                    <div>
                                        <p className='legend semi-bold mb-3'>Profile image <span className='hint light-iron'>(optional)</span></p>
                                        <div className='row'>
                                            <div className='col-auto my-auto'>
                                                {
                                                    iconUrl !== "" && <img className="app-icon profile-settings my-auto" src={iconUrl} alt="app_logo" width={48}></img>
                                                }

                                                {
                                                    icon !== null && iconUrl === '' && <img className="app-icon profile-settings my-auto" src={icon} alt="app_logo" width={48}></img>
                                                }

                                                {
                                                    icon == null && iconUrl === '' && <img className="app-icon profile-settings my-auto" src={placeholder} alt="app_logo" width={48}></img>
                                                }
                                            </div>
                                            <div className='col-auto'>
                                                <input onChange={loadSubscriptionImage} type="file" className="form-control form-control-sm" id="formFileSm" accept='image/png, image/jpeg' />
                                            </div>
                                            <p className='iron bold legend my-2'>Max. size: 1Mb</p>
                                        </div>
                                    </div>
                                }

                                <div className='col-sm mb-3 mt-3'>
                                    <label className='iron semi-bold legend mb-2'>Your name</label>
                                    <input value={name} onChange={updateName} className='form-control' type="text" name="app_name" id="appName"></input>
                                    {validation.name && <p className='ms-2 mt-2 bloodie legend semi-bold'>{validation.name}</p>}
                                </div>
                                <div className='col-sm mb-3 mt-3'>
                                    <label className='iron semi-bold legend mb-2'>Your business name <span className='hint light-iron'>(optional)</span></label>
                                    <input value={businessName} onChange={updateBusinessname} className='form-control' type="text" name="app_name" id="appName"></input>
                                </div>
                                <div className='col-sm mb-3 mt-3'>
                                    <label className='iron semi-bold legend mb-2'>E-mail</label>
                                    <input value={email} onChange={updateEmail} className='form-control' type="email" name="app_name" id="appName"></input>
                                    {validation.email && <p className='ms-2 mt-2 bloodie legend semi-bold'>{validation.email}</p>}
                                </div>
                                <div className='col-sm'>
                                    {(targetPlan && subscription) &&
                                        <div>
                                            <label className='iron semi-bold legend mb-2 mt-3'>Plan</label>
                                            <input value={targetPlan} disabled={true} className='form-control' type="email" name="app_name" id="appName"></input>
                                        </div>
                                    }
                                    {(!targetPlan && !subscription) &&
                                        <div>
                                            <label className='iron semi-bold legend mb-2 mt-3'>Plan</label>
                                            <select value={plan} onChange={(e: any) => updatePlan(e)} className='form-select form-select-lg mb-3' aria-label=".form-select-lg example">
                                                {
                                                    plans &&
                                                    plans.map((plan: any, index: any) => {
                                                        return (
                                                            <option><a className='dropdown-item iron legend' href="#">{plan.name}</a></option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </div>
                                {!subscription &&
                                    <div className='row mt-5'>
                                        <div className='col'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={checked}
                                                    onChange={handleCheckChange}></input>
                                                <label className="form-check-label">
                                                    <p className='legend light-iron'>I have read and agree to <a href='terms'><span className='apporange underlined'>Terms and conditions</span></a> and the <a href='privacy'><span className='apporange underlined'>Privacy policy</span></a></p>
                                                </label>
                                            </div>
                                            {validation.checked && <p className='ms-2 bloodie legend semi-bold'>{validation.checked}</p>}
                                        </div>
                                    </div>
                                }
                                <div className='row p-2 mt-5'>
                                    {(!subscription && (targetPlan === 'Indie' || targetPlan === 'Business' || plan === 'Indie' || plan === 'Business')) &&
                                        <p className='iron legend semi-bold'>In order to complete your registration you will be redirected to a checkout page provided by our payments partner <span><a href='https://stripe.com'>Stripe</a></span>.</p>
                                    }
                                    <button type="button" onClick={saveNewSubscription} className='btn btn-cta-primary bg-apporange white bold p-2'>{subscription !== undefined ? 'Save changes' : 'Start now'}</button>
                                </div>
                                {!subscription &&
                                    <div className='mt-3'>
                                        <p className='text-center legend light-iron mb-5'>Already a client? <a href='' onClick={close}><span className='apporange underlined'>Sign in</span></a></p>
                                    </div>
                                }
                            </div>
                        </div>
                        {subscription &&
                            <div className='col'>
                                {subscription.plan == 'A' &&
                                    <PricingComponent
                                        plan='Basic'
                                        mode={mode}
                                        description={'An excellent entry point for those who want to get a feel for our services without any cost. This plan provides access to essential features, making it ideal for individual users or small-scale projects.'}
                                        features={[
                                            "1 App",
                                            "Reviews",
                                            "Heart beat",
                                            "2 topics on Keywords",
                                            "Last month report"
                                        ]}
                                        subscription={true}
                                        manage={true}
                                        handler={openCustomerPortal} />
                                }
                                {subscription.plan == 'AA' &&
                                    < PricingComponent
                                        plan='Indie'
                                        mode={mode}
                                        description={'For independent developers and small teams, our Indie Plan is the perfect choice. Affordable and feature-rich, it\'s the next step for those looking to elevate their projects.'}
                                        features={[
                                            "up to 3 Apps",
                                            "Reviews",
                                            "Heart beat",
                                            "5 topics on Keywords",
                                            "3 months report",
                                            "Radar"
                                        ]}
                                        price={mode == 'monthly' ? "€19,90" : "€199"}
                                        subscription={true}
                                        manage={true}
                                        handler={openCustomerPortal} />
                                }
                                {subscription.plan == 'AAA' &&
                                    < PricingComponent
                                        plan='Business'
                                        mode={mode}
                                        description={'Businesses with multiple apps and complex requirements will find our Business Plan tailored to their needs. With premium features for collaboration and analytics, this plan ensures your business can thrive in the digital landscape.'}
                                        features={[
                                            "up to 10 Apps",
                                            "Reviews",
                                            "Heart beat",
                                            "Unlimited topics on Keywords",
                                            "6 months report",
                                            "Radar",
                                            "Pathfinder",
                                            "Snapshot"
                                        ]}
                                        price={mode == 'monthly' ? "€49,90" : "€499"}
                                        subscription={true}
                                        manage={true}
                                        handler={openCustomerPortal} />
                                }
                                {subscription.plan == 'U' &&
                                    < PricingComponent
                                        plan='Tailored'
                                        mode={mode}
                                        description={'Enjoy unlimited app management, along with premium tools. This plan is designed to meet the specific needs of your business. \
                                        `Contact us to discuss a personalized plan that aligns perfectly with your goals and aspirations.'}
                                        features={[
                                            "Unlimited apps",
                                            "Reviews",
                                            "Heart beat",
                                            "Unlimited topics on Keywords",
                                            "Unlimited reports",
                                            "Radar",
                                            "Pathfinder",
                                            "Snapshot",
                                            "Proactive feedback",
                                            "API access"
                                        ]}
                                        hide={true}
                                        subscription={true}
                                        manage={true}
                                        handler={openCustomerPortal} />
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </div >
    );
}

export default ClientManagementComponent;