import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import '../styles/pricing-card.scss';
import '../styles/typography.scss';
import '../styles/pills.scss'
import SegmentedControl from './SegmentedControl';

function PricingComponent({ plan, mode, description, features, price, hide, featured, subscription, manage, handler }: any) {
    const featuresList = features.map((feature: any) =>
        <li className='pricing-item'><span className='greenie'>✔  </span>{feature}</li>
    );

    const className = featured ? 'pricing-card large shadow-lg text-center' : 'pricing-card shadow-lg text-center';
    const modeLabel = mode == "monthly" ? "Month" : "Year"

    const openCustomerPortal = (event: any) => {
        handler()
    }

    return (
        <div className={className}>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <h2 className='iron bold mb-3'>{plan}</h2>
                </div>
                <div className=''>
                </div>
            </div>

            <p className='light-iron mb-5'>{description}</p>
            {!hide &&
                <div>
                    {price &&
                        <h2 className='heading bold mb-5'>
                            <span className='light-iron legend'></span>{price}<span className='light-iron legend'> /{modeLabel}</span>
                        </h2>
                    }
                    {!price &&
                        <h2 className='bold mb-5'>Free</h2>
                    }
                </div>
            }
            {!subscription &&
                <div className='mb-3'>
                    {hide &&
                        <a href='mailto:main@appinion.pt?subject=Quote from site&body=Hi Appinion, We would like to know more about your tailored plan. Can we schedule a demo?'>
                            <button style={{ width: 50 + '%' }} className='btn btn-primary'>
                                Get a quote
                            </button>
                        </a>
                    }
                    {!hide &&
                        <Link to={'/subscribe?plan=' + plan + '&mode=' + mode}>
                            <button style={{ width: 50 + '%' }} className='btn btn-primary'>
                                {!price ? "Start now" : "Subscribe"}
                            </button>
                        </Link>
                    }
                </div>
            }
            {features && features.length &&
                <div>
                    <p className='mt-4 light-iron legend'>Includes:</p>
                    <ul>
                        {featuresList}
                    </ul>
                </div>
            }
            {(manage && (plan === 'Indie' || plan === 'Business')) === true &&
                <div>
                    <div className='light-iron legend semi-bold mb-3 ms-3'>
                    </div>
                    <div className='subscription-card mt-5'>
                        <button type="button" onClick={openCustomerPortal} className='btn bg-iron white bold mt- p-3'>Manage my subscription</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default PricingComponent;